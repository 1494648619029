import styled from '@emotion/styled';
import { Box, Button, Grid, Typography } from '@mui/material';
import { APP } from 'src/styles/variables';
import InfoIcon from '@mui/icons-material/Info';

export const QueueTopBarStyled = styled('div')`
  width: 100%;
  height: 6rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 0 0.5rem;
  border-bottom: 0.5px solid ${APP.PALETTE.GREY.SEMI_GRAY};
`;

export const MarkButtonStyled = styled(Button)`
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_12PX};
  font-weight: ${APP.FONT.FONT_WEIGHT.CAPTION};
  color: ${APP.PALETTE.COMMON.BLACK};
  border-color: ${APP.PALETTE.GREY.SEMI_GRAY};
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 51;
  width: 92%;
  height: 100%;
`;

export const IframeContainerStyled = styled('div')`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
`;

export const IframeStyled = styled('iframe')`
  width: 100%;
  height: 100%;
  border: none;
  box-sizing: border-box;
`;

export const EmailViewerAndKeyIdentifiersContainer = styled.div`
  width: 35%;
  min-width: 35%;
`;

export const EmailViewerContainer = styled.div`
  height: 70%;
`;

export const KeyIdentifiersContainer = styled.div`
  height: 30%;
`;

export const KeyItemsDivStyled = styled('div')`
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-weight: ${APP.FONT.FONT_WEIGHT.CAPTION};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.625rem;
`;

export const EditButtonStyled = styled(Button)<{ disabled?: boolean }>`
  color: ${APP.FONT.FONT_COLOR.SECONDARY};
  text-decoration: underline;
  text-transform: capitalize;
  &.Mui-disabled {
    pointer-events: auto;
    cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;
export const KeyItemsContainer = styled(Box)<{ isQueueAiDetailsTab?: boolean }>`
  padding: ${({ isQueueAiDetailsTab = false }) =>
    isQueueAiDetailsTab ? '' : '2rem'};
  background-color: ${APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.PRIMARY};
  height: 100%;
  width: 100%;
  overflow: scroll;
  @media only screen and (max-width: 1000px) {
    flex-wrap: wrap;
    margin-bottom: 0.375rem;
  }
`;
export const KeyItem = styled('div')<{ isEditMode?: boolean }>`
  display: flex;
  align-items: ${({ isEditMode = false }) =>
    isEditMode ? 'center' : 'flex-start'};

  .key {
    width: 30%;
    text-align: left;
    font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_12PX};
    font-weight: ${APP.FONT.FONT_WEIGHT.CAPTION};
    padding-right: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .value {
    width: 70%;
    text-align: left;
    padding-left: 0.5rem;
    font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_12PX};
    font-weight: ${APP.FONT.FONT_WEIGHT.FW_600};
    margin-bottom: 1.3rem;
  }
`;

export const TextArea = styled.textarea`
  margin: 0.5rem;
  width: 100%;
  padding: 0.5rem;
  resize: vertical;
  overflow: auto;
  min-height: 3rem;
  font-family: inherit;
  font-size: 1rem;
  border-radius: 4px;
`;

export const TooltipStyled = styled('div')`
  background-color: ${APP.PALETTE.COMMON.BLACK_SUB};
  color: ${APP.PALETTE.COMMON.WHITE};
  padding: 0.5rem;
  border-radius: 5px;
  visibility: ${({ visible }: any) => (visible ? 'visible' : 'hidden')};
`;

export type EmailViewerWrapperType = {
  isEmailViewerOnLeft?: boolean;
};

export const EmailRichTextViewerWrapper = styled.div<EmailViewerWrapperType>`
  height: 100%;
  overflow: scroll;
  border-bottom: ${({ isEmailViewerOnLeft = false }) =>
    isEmailViewerOnLeft ? 'none' : `10px solid ${APP.PALETTE.GREY.SEMI_GRAY}`};
  width: ${({ isEmailViewerOnLeft = false }) =>
    isEmailViewerOnLeft ? '70%' : 'auto'};
`;

export const AutoCompletedTypography = styled(Typography)`
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-weight: ${APP.FONT.FONT_WEIGHT.FW_600};
  padding-right: 1.5rem;
`;

// Task Exceptions starts

export const TaskExceptionsMainContainer = styled.div`
  padding-top: 2.375rem;
  padding-left: 2.25rem;
  padding-right: 1.875rem;
  overflow-wrap: anywhere;
  height: 100%;
  width: 35%;
  min-width: 35%;
  overflow: scroll;
  background-color: ${APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.PRIMARY};
`;

export const TaskExceptionDetailsContainer = styled.div``;

export const TaskExceptionDetails = styled.div`
  margin-bottom: 13px;
`;

export const TaskExceptionLabel = styled.div`
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_16PX};
  font-family: ${APP.FONT.FONT_FAMILY.BOLD};
`;

export const TaskExceptionValue = styled(TaskExceptionLabel)`
  font-family: ${APP.FONT.FONT_FAMILY.SEMIBOLD};
`;

export const Hr = styled.div`
  margin-top: 1.5625rem;
  margin-bottom: 1.5625rem;
  border-bottom: 1px solid ${APP.PALETTE.GREY.SEMI_GRAY};
`;

// Task Exceptions ends

// DB Exceptions starts
export const DBQueryMainContainer = styled.div`
  padding-top: 2.375rem;
  padding-left: 2.25rem;
  padding-right: 1.875rem;
  overflow-wrap: anywhere;
  height: 100%;
  width: 35%;
  min-width: 35%;
  overflow: scroll;
  background-color: ${APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.PRIMARY};
`;
export const DBQueryDetailsContainer = styled.div``;
export const DBQueryDetails = styled.div`
  margin-bottom: 13px;
`;
export const DBQueryMainDetails = styled.div`
  margin-bottom: 13px;
`;
export const DBQueryLabel = styled.div`
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_16PX};
  font-family: ${APP.FONT.FONT_FAMILY.BOLD};
`;
export const DBQueryValue = styled(DBQueryLabel)`
  font-family: ${APP.FONT.FONT_FAMILY.SEMIBOLD};
`;
// DB Exceptions ends

// Task details and action taken starts

export const TaskDetailsAndActionTakenContainer = styled.div`
  background-color: ${APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.PRIMARY};
  overflow: auto;
  width: 30%;
  min-width: 30%;
`;

export const TaskDetailsSection = styled.div``;

export const ActionTakenSection = styled.div``;

export const KeyIdentifiersSection = styled.div``;

export const TaskDetailsHeaderGrid = styled(Grid)`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0.625rem;
`;

export const TaskDetailsHeaderTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${APP.FONT.FONT_SIZE.H6};
  font-family: ${APP.FONT.FONT_FAMILY.BOLD};
`;

export const TaskDetailContentWrapperGrid = styled(Grid)`
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 0.625rem;
  overflow: auto;
  margin-top: 0.6rem;
`;

export const TaskDetailContentGrid = styled(Grid)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 0.937rem;
  margin-bottom: 0.8rem;
  @media only screen and (max-width: 1000px) {
    flex-wrap: wrap;
    margin-bottom: 0.375rem;
  }
`;

export const TaskDetailTypographyLabel = styled(Typography)`
  width: 20%;
  height: 100%;
  color: ${APP.PALETTE.GREY.TERTIARY_COLOR};
  font-size: 0.938rem;
  font-family: ${APP.FONT.FONT_FAMILY.BOLD} @media only screen and
    (max-width: 1400px) {
    word-break: break-all;
  }
`;

export const TaskDetailTypographyValue = styled(Typography)`
  flex: 1;
  height: 100%;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-family: ${APP.FONT.FONT_FAMILY.BOLD};
`;

export const TaskDetailsLoadingGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3.125rem;
`;

export const TaskDetailsLoadingGridInQueueDetails = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const TaskDetailsDiv = styled.div`
  overflow: auto;
  padding-left: 1.25rem;
  padding-top: 1.875rem;
`;

export const TaskDetailsNoDataGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3.125rem;
`;

export const TaskDetailsNoDataMessageTypography = styled(Typography)`
  color: ${APP.PALETTE.GREY.SHADE_A700};
  font-size: ${APP.FONT.FONT_SIZE.BODY1};
  font-family: ${APP.FONT.FONT_FAMILY.SEMIBOLD};
`;

export const ActionDetailsDiv = styled.div`
  overflow: auto;
  padding-left: 1.25rem;
  padding-top: 1.3rem;
`;

export const ActionTakenDetailGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 0.937rem;
  margin-bottom: 1.375rem;
  @media only screen and (max-width: 1000px) {
    flex-wrap: wrap;
    margin-bottom: 0.375rem;
  }
`;

export const ActionTakenHeaderGrid = styled(Grid)`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0.625rem;
`;

export const ActionTakenHeaderTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${APP.FONT.FONT_SIZE.H6};
  font-family: ${APP.FONT.FONT_FAMILY.BOLD};
`;

export const ActionTakenDetailContentGrid = styled('div')``;

export const ActionTakenMainDetails = styled.div`
  margin-bottom: 0.8rem;
  margin-left: 1.25rem;
`;

export const ActionTakenContentWrapperGrid = styled(Grid)`
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 0.625rem;
  overflow: auto;
  margin-top: 0.6rem;
`;

export const ActionTakenDetailLabel = styled.div`
  margin-bottom: 0.5rem;
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_16PX};
  font-family: ${APP.FONT.FONT_FAMILY.BOLD};
`;
export const ActionTakenDetailTypographyLabel = styled.div`
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE2};
  font-family: ${APP.FONT.FONT_FAMILY.BOLD};
`;
export const ActionTakenDetailTypographyValue = styled(
  ActionTakenDetailTypographyLabel,
)`
  font-family: ${APP.FONT.FONT_FAMILY.SEMIBOLD};
`;

export const ActionTakenDetailsLoadingGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 3.125rem;
`;

export const ActionTakenDetailsNoDataGrid = styled(Grid)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 1rem;
  margin-bottom: 1.125rem;
`;

export const ActionTakenDetailsNoDataMessageTypography = styled(Typography)`
  color: ${APP.PALETTE.GREY.SHADE_A700};
  font-size: ${APP.FONT.FONT_SIZE.BODY1};
  font-family: ${APP.FONT.FONT_FAMILY.SEMIBOLD};
`;

export const KeyIdentifiersDiv = styled.div`
  overflow: auto;
  padding-left: 1.25rem;
  padding-top: 1.3rem;
`;

export const KeyIdentifiersHeaderGrid = styled(Grid)`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0.625rem;
`;

export const KeyIdentifiersHeaderTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${APP.FONT.FONT_SIZE.H6};
  font-family: ${APP.FONT.FONT_FAMILY.BOLD};
`;

export const KeyIdentifiersNoDataGrid = styled(Grid)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 1.3rem;
`;

export const KeyIdentifiersNoDataMessageTypography = styled(Typography)`
  color: ${APP.PALETTE.GREY.SHADE_A700};
  font-size: ${APP.FONT.FONT_SIZE.BODY1};
  font-family: ${APP.FONT.FONT_FAMILY.SEMIBOLD};
`;

export const KeyIdentifiersContentWrapperGrid = styled(Grid)`
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 0.625rem;
  overflow: auto;
  margin-top: 0.6rem;
`;

export const KeyIdentifiersHeaderAndInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const KeyIdentifiersHeaderContainer = styled.div`
  display: flex;
  padding-top: 0.125rem;
`;

export const KeyIdentifiersInfoContainer = styled.div`
  display: flex;
  margin-left: 0.313rem;
`;

export const KeyIdentifiersInfoStyledList = styled.ul`
  margin: 0;
  padding: 0.313rem 0 0 1rem;
  list-style-type: disc;
  font-size: 0.813rem;
`;

export const KeyIdentifiersInfoStyledListItem = styled.li`
  margin-bottom: 0.5rem;
`;

export const StyledInfoIcon = styled(InfoIcon)`
  color: ${APP.FONT.FONT_COLOR.SECONDARY};
  cursor: pointer;
  font-size: 1.1rem;
`;

// Task details and action taken ends

// Task Type and State NSA dropdown starts

export const UnclassifiedUnFlaggedDetailsWrapperGrid = styled(Grid)`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const EmailWrapperGrid = styled(Grid)`
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

export const DropdownSectionWrapperGrid = styled(Grid)`
  width: 30%;
  min-width: 30%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: ${APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.PRIMARY};
  padding: 1.875rem;
  flex-direction: column;
  @media only screen and (max-width: 1000px) {
    padding: 1.875rem 0.5rem;
  }
`;

export const DropdownInputsWrapperGrid = styled(Grid)`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

export const DropDownInputSectionTypography = styled(Typography)`
  width: 100%;
  text-align: left;
  color: ${APP.PALETTE.TEXT.PRIMARY};
  font-size: ${APP.FONT.FONT_SIZE.H6};
  font-weight: ${APP.FONT.FONT_WEIGHT.CAPTION};
`;

export const DropdownLabelTypography = styled(Typography)`
  color: ${APP.PALETTE.TEXT.PRIMARY};
  font-size: ${APP.FONT.FONT_SIZE.BODY1};
  font-weight: ${APP.FONT.FONT_WEIGHT.CAPTION};
`;

export const ButtonStyled = styled(Button)<{ disabled?: boolean }>`
  text-transform: capitalize;
  border-radius: 10px;
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE1};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  margin-bottom: 0.5rem;
  width: 7.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.75rem;
  margin: 0;
  &.Mui-disabled {
    pointer-events: auto;
    cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const SaveTextButton = styled(ButtonStyled)<{ disabled?: boolean }>`
  cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  background-color: ${APP.FONT.FONT_COLOR.SECONDARY};
  border-radius: 0.313rem;
  color: ${APP.PALETTE.COMMON.WHITE};
  &:hover {
    box-shadow: ${APP.PALETTE.GREY.BOX_SHADOW.GRAY};
    background-color: ${APP.FONT.FONT_COLOR.SECONDARY};
    cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const DropdownLoaderGrid = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.25rem 0.5rem;
`;

export const UnClassifiedUnflaggedSectionGrid = styled(Grid)`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
`;

export const DropDownInputGrid = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;
`;

export const DropDownSubmitButtonWrapperGrid = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 2rem;
`;

// Task Type and State NSA dropdown ends

// Email details not found starts

export const EmailDetailsNotFoundViewerWrapper = styled.div<EmailViewerWrapperType>`
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmailDetailsNotFoundTypography = styled(Typography)`
  color: ${APP.PALETTE.GREY.SHADE_A400};
  font-size: ${APP.FONT.FONT_SIZE.BODY1};
  font-weight: 600;
`;

export const EmailDetailsNotFoundLoadingGrid = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Email details not found ends

// Status Reason starts

export const StatusReasonMainContainer = styled.div`
  margin-bottom: 13px;
  width: 100%;
`;
export const StatusReasonLabel = styled.div`
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_16PX};
  font-family: ${APP.FONT.FONT_FAMILY.BOLD};
`;
export const StatusReasonValue = styled(StatusReasonLabel)`
  font-family: ${APP.FONT.FONT_FAMILY.SEMIBOLD};
`;
export const StatusReasonHeadingTypography = styled(Typography)`
  width: 100%;
  text-align: left;
  color: ${APP.PALETTE.TEXT.PRIMARY};
  font-size: ${APP.FONT.FONT_SIZE.H6};
  font-weight: ${APP.FONT.FONT_WEIGHT.CAPTION};
`;

// Status Reason ends

// Status Reason For HistoryTab starts

export const StatusReasonMainContainerForHistoryTab = styled.div`
  padding-top: 2.375rem;
  padding-left: 2.25rem;
  padding-right: 1.875rem;
  height: 100%;
  width: 35%;
  min-width: 35%;
  overflow: scroll;
  background-color: ${APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.PRIMARY};
`;

// Status Reason ends For HistoryTab

// Retry button for failed tasks starts

export const RetryButtonStyled = styled(ButtonStyled)<{ disabled?: boolean }>`
  cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  background-color: ${APP.FONT.FONT_COLOR.SECONDARY};
  color: ${APP.PALETTE.COMMON.WHITE};
  &:hover {
    box-shadow: ${APP.PALETTE.GREY.BOX_SHADOW.GRAY};
    background-color: ${APP.FONT.FONT_COLOR.SECONDARY};
    cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

// Retry button for failed tasks ends

// Archive button for unClassified and unFlagged tasks starts

export const ArchiveButtonStyled = styled(ButtonStyled)<{ disabled?: boolean }>`
  cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  background-color: ${APP.FONT.FONT_COLOR.SECONDARY};
  color: ${APP.PALETTE.COMMON.WHITE};
  border-radius: 5px;
  width: 5.5rem;
  &:hover {
    box-shadow: ${APP.PALETTE.GREY.BOX_SHADOW.GRAY};
    background-color: ${APP.FONT.FONT_COLOR.SECONDARY};
    cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const ArchiveButtonLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${APP.PALETTE.BACKGROUND.WHITE_WITH_OPACITY};
  z-index: 1000;
`;

// Archive button for unClassified and unFlagged tasks ends
